<template>
    <div>
        <banner banner-name="分类" :background-img="$store.getters.getBackground('categories')"></banner>
        <v-card class="blog-container  an-blog-InUp">
            <div class="categories-title">
                <span class="mr-1">分类-</span> <span>{{categoriesData.length}}</span>
            </div>
            <ul class="categories-list">
                <router-link tag="li" :to="'/categories/'+categorie.id" :key="categorie.id" v-for="categorie in categoriesData" class="categories-item animate__animated animate__fadeInUp">
                    {{categorie.categorie_name}}<span class="ml-2">({{categorie.categorie_count}})</span>
                </router-link>

            </ul>
        </v-card>
    </div>
</template>

<script>
  import Banner from "@/components/banner/Banner";
  import {getArticlesCategoriesApi} from "@/network/interface";

  export default {
    name: "Categories",
    data() {
      return {
        categoriesData: []
      }
    },
    created() {
      this.getArticlesCategories()
    },
    methods: {
      getArticlesCategories() {
        getArticlesCategoriesApi({}).then(resp => {
          this.categoriesData = resp.data
        })

      }
    },
    components: {
      Banner
    }
  }
</script>

<style scoped>

    .categories-title {
        text-align: center;
        font-size: 2rem;
        line-height: 2;
    }

    .categories-item {
        height: 40px;
        padding: 8px 0;
    }

    .categories-item span {
        font-size: 0.7rem;
        color: #a7a7a7;
    }

    .categories-item:hover,
    .categories-item:hover span {
        color: #9896dc;
    }


    .categories-item::before {
        display: inline-block;
        position: relative;
        left: -.75rem;
        width: 12px;
        height: 12px;
        border: .2rem solid #49b1f5;
        border-radius: 50%;
        background: #fff;
        content: "";
        transition-duration: .3s;
    }

    .categories-item:hover::before {
        border: .2rem solid #ff7242;
        transform: scale(1.2);
    }
</style>
